import { css, LitElement } from "lit"
import algoliasearch from "algoliasearch/lite"
import algoliasearchHelper from "algoliasearch-helper"
import { debounce } from "#js/components/utils"
import { fetchJSON } from "#js/components/http"
import { filterItems } from "#js/components/algoliaUtils"
import insightsClient from "search-insights"
import { recommendClient } from "@algolia/recommend"

insightsClient("init", {
  appId: globalThis.algoliaAppId,
  apiKey: globalThis.algoliaAPIKey,
  authenticatedUserToken: globalThis.userId,
})

/**
 * Component for aggregating filter and display results from Algolia.
 * @property {number} hitsPerPage - the number of search results to display per page
 * @property {string} paginationId - the id of the pagination component
 * @property {Array} events - the search results
 * @property {string} settingsUrl - the URL to fetch the settings from
 * @property {string} extraFilters - additional filters to apply to the search query
 * @property {string} itemListId - the id of the list of items
 * @property {string} itemListName - the name of the list of items
 */
export class AlgoliaSearch extends LitElement {
  static properties = {
    for: { type: String },
    hitsPerPage: { type: Number },
    paginationId: { type: String },
    events: { type: Array, attribute: false },
    indexName: { type: String },
    settingsUrl: { type: String },
    extraFilters: { type: String },
    secondaryLanguageEnabled: { type: Boolean },
    itemListId: { type: String },
    itemListName: { type: String },
    error: { type: Boolean, attribute: false },
  }

  // That way the component doesn't create gaps in grids
  static styles = css`
    :host {
      position: absolute;
    }
  `

  /**
   * Debounced search function to avoid too many requests.
   */
  debouncedSearch = debounce(() => {
    this.helper.search()
  }, 350)

  async firstUpdated() {
    // initialize the Algolia search helper
    const client = algoliasearch(globalThis.algoliaAppId, globalThis.algoliaAPIKey)
    this.helper = algoliasearchHelper(client, this.indexName, {
      facets: ["is_virtual"],
      disjunctiveFacets: [
        "activity_type",
        "topic_ids",
        "languages",
        "target_group_ids",
      ],
      hitsPerPage: this.hitsPerPage,
    })

    this.eventRenderer = document.getElementById(this.for)

    const settings = await fetchJSON(this.settingsUrl)
    // require settings to exist
    if (settings) {
      this.eventRenderer.applySettings(settings)

      let filters = settings.filters
      if (this.extraFilters) {
        filters += ` AND ${this.extraFilters}`
      }

      if (this.secondaryLanguageEnabled) {
        filters = filters.replace(
          `languages:${globalThis.language}`,
          `(languages:${globalThis.language} OR secondary_languages:${globalThis.language})`,
        )
      }

      this.helper.setQueryParameter("filters", filters)
      if (settings.distinct !== undefined) {
        this.helper.setQueryParameter("distinct", false)
      }
      // This is needed in order to have a queryID information in the response.
      this.helper.setQueryParameter("clickAnalytics", true)
      this.helper.on("error", this.onError)
      this.helper.on("result", this.onResult.bind(this))

      if (this.paginationId) {
        this.pagination = document.getElementById(this.paginationId)
      }

      this.loadInitialQueryFromUrl(this.helper)
      this.debouncedSearch()
      this.ready = true
    } else {
      this.eventRenderer.error = true
    }
  }

  /**
   * Load the initial query and page from the URL
   * and apply it to the helper.
   * @param {algoliasearchHelper} helper - the search query builder (algoliasearchHelper)
   */
  loadInitialQueryFromUrl(helper) {
    const urlParams = new URLSearchParams(globalThis.location.search)
    if (urlParams.get("query")) {
      helper.setQuery(urlParams.get("query"))
    }
    if (urlParams.get("page")) {
      helper.setPage(urlParams.get("page"))
    }
  }

  onError(event) {
    throw event.error
  }

  /**
   * Handle search results, transform the offers, update pagination.
   * @param {object} event - algolia event containing the search hits
   */
  onResult(event) {
    const hits = filterItems(event.results.hits)
    this.eventRenderer.eventsChanged(
      hits,
      this.hitsPerPage,
      event.results.page,
      event.results.queryID,
    )
    sendAlgoliaViewEvents(hits)
    if (this.pagination) {
      this.pagination.setPage(event.results.page, event.results.nbPages)
    }
  }

  /**
   * Hook for filter components to notify the search component
   * that the filter has changed.
   * @param {object} filter - the filter component that has changed
   */
  onFilterChanged(filter) {
    filter.applyFilter(this.helper)
    if (this.ready) {
      this.debouncedSearch()
    }
  }
}

globalThis.customElements.define("algolia-search", AlgoliaSearch)

export class AlgoliaRecommendations extends LitElement {
  static properties = {
    for: { type: String },
    indexName: { type: String },
    objectID: { type: String },
    fallbackQuery: { type: String },
    model: { type: String },
    threshold: { type: Number },
    maxRecommendations: { type: Number },
    settingsUrl: { type: String },
  }

  static styles = css`
    :host {
      position: absolute;
    }
  `

  async firstUpdated() {
    this.client = recommendClient(globalThis.algoliaAppId, globalThis.algoliaAPIKey)
    const settings = await fetchJSON(this.settingsUrl)
    const filters = settings.recommendation_filters

    this.eventRenderer = document.getElementById(this.for)
    this.eventRenderer.applySettings(settings)

    const params = {
      indexName: this.indexName,
      model: this.model,
      threshold: this.threshold,
      maxRecommendations: this.maxRecommendations,
      queryParameters: {
        filters,
      },
    }

    if (this.objectID) {
      params.objectID = this.objectID
    }

    if (this.fallbackQuery) {
      params.fallbackParameters = {
        filters,
        query: this.fallbackQuery,
      }
    }

    const request = this.client.getRecommendations([params])

    try {
      const response = await request
      this.onResult(response.results[0])
    } catch (error) {
      // let the event renderer know that there are no results
      this.onResult({ hits: [] })
      console.error(error)
      this.remove()
    }
  }

  /**
   * Handle search results, transform the offers, update pagination.
   * @param {object} result - algolia event containing the search hits
   */
  onResult(result) {
    const hits = filterItems(result.hits)
    this.eventRenderer.eventsChanged(
      hits,
      this.maxRecommendations,
      0,
      result.queryID,
    )
    sendAlgoliaViewEvents(hits)
  }
}

export function sendAlgoliaViewEvents(hits) {
  // Algolia can process a list of 20 objectIDs at once,
  // so we split the list into chunks of 20 and send them to the insights client.
  const objectIDs = hits.map((hit) => hit.objectID.toString())
  objectIDs.forEach((_, index) => {
    if (index % 20 === 0) {
      insightsClient("viewedObjectIDs", {
        authenticatedUserToken: globalThis.userId,
        index: "event",
        eventName: "Items Viewed",
        objectIDs: objectIDs.slice(index, index + 20),
      })
    }
  })
}

globalThis.customElements.define("algolia-recommendations", AlgoliaRecommendations)

export function sendAlgoliaClickEvent(algoliaAnalytics) {
  if (algoliaAnalytics) {
    // Temporary logging to identify missing queryIDs
    if (!algoliaAnalytics.queryID) {
      throw new Error(
        `Missing queryID in algoliaAnalytics: ${JSON.stringify(algoliaAnalytics)}`,
      )
    }

    insightsClient("clickedObjectIDsAfterSearch", {
      authenticatedUserToken: globalThis.userId,
      eventName: "Item Selected",
      index: "event",
      queryID: algoliaAnalytics.queryID,
      objectIDs: [algoliaAnalytics.objectID.toString()],
      positions: [algoliaAnalytics.position],
    })
  }
}

export function getAlgoliaAnalyticsData(
  hit,
  index,
  hitsPerPage,
  currentPage,
  algoliaQueryId,
) {
  return {
    objectID: hit.objectID,
    queryID: algoliaQueryId,
    // Calculation of position is based on the current page and hitsPerPage.
    // https://www.algolia.com/doc/api-reference/api-methods/clicked-object-ids-after-search/#method-param-positions
    position: currentPage * hitsPerPage + index + 1,
  }
}

/**
 * Convert a date from Algolia to a JS date.
 * Algolia stores dates in seconds, JS uses milliseconds.
 * @param {number} date - the date in Algolia format
 * @returns {Date} - the date in JS format
 */
export function fromAlgoliaDate(date) {
  return new Date(date * 1000)
}
